<template>
  <tj-layout
    title="管理后台"
    showLogo="images/logo.png"
    :menu="routes"
    :route="route"
    @goto="routerLink"
  >
    <router-view v-slot="{ Component }">
      <template v-if="Component">
        <suspense>
          <component :is="Component"></component>
        </suspense>
      </template>
    </router-view>
    <template #navbar>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        @command="handleCommand"
      >
        <div class="avatar-wrapper">
          <span class="user-avatar">{{ user.name }}</span>
          <el-icon class="el-icon--right">
            <ArrowDown></ArrowDown>
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>
  </tj-layout>
</template>

<script setup>
import { ArrowDown } from '@element-plus/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
import request from '@/hooks/request';

const router = useRouter();
const route = useRoute();

const routerLink = (to) => {
  router.push(to);
};

function handleCommand(command) {
  if (command === 'logout') {
    request.post('/admin/user/logout').then(() => {
      store.dispatch('resetToken');
      window.location.reload();
    });
  }
}

const { routes, user } = store.getters;
</script>

<style lang="less">
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }
}

.avatar-container {
  margin-right: 30px;

  .avatar-wrapper {
    margin-top: 5px;
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 14px;

    .user-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }

    // .el-icon-caret-bottom {
    //   cursor: pointer;
    //   position: absolute;
    //   right: -20px;
    //   top: 25px;
    //   font-size: 12px;
    // }
  }
}
</style>
